import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";

import CallIcon from "@mui/icons-material/Call";
import StoreIcon from "@mui/icons-material/Store";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { UserContext } from "../accountManager/AccountManager";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LanguageSelector from "./LanguageSelector";
import TranslatedTypography from "../Language/TranslatedTypography";

const defaultButtons = [
  {
    name: "KONTAKT",
    Icon: CallIcon,
    path: "/contact",
  },
  {
    name: "LAGER",
    Icon: InventoryIcon,
    path: "/used",
  },
  {
    name: "PULVERLACK",
    Icon: StoreIcon,
    path: "https://pulverlack.net",
  },
];

const NavMenu = ({ column = false, ...props }) => {
  const [buttons, setButtons] = useState(defaultButtons);
  const { name } = useContext(UserContext);

  const location = useLocation();

  useEffect(() => {
    if (!name) return;

    setButtons([
      ...defaultButtons,
      { name: name, path: "/user", Icon: AccountCircleIcon, translate: false },
    ]);
  }, [name]);

  const [currentButtonName, setCurrentButtonName] = useState();

  useEffect(() => {
    const getButton = () => {
      let matched = [...buttons]
        .reverse()
        .find((button) => location.pathname.includes(button.path));
      if (matched) return `${matched.name}`;
      return `${buttons[0].name}`;
    };
    setCurrentButtonName(getButton());
  }, [location, buttons]);

  const navigate = useNavigate();

  return (
    <Box {...props}>
      <List
        component={Stack}
        direction={column ? "column" : "row"}
        sx={{ paddingBottom: 0 }}
      >
        {buttons.map((button) => {
          const selected = button.name === currentButtonName;

          return (
            <ListItemButton
              sx={{ paddingBottom: 0 }}
              onClick={() => {
                if (button.path.startsWith("http"))
                  return window.open(button.path, "_blank");
                setCurrentButtonName(button.name);
                navigate(button.path);
              }}
              key={button.name}
            >
              <Stack
                direction={column ? "row" : "column"}
                justifyContent="flex-end"
                alignItems="center"
              >
                {column && (
                  <Box
                    sx={{
                      width: "2px",
                      height: "20px",
                      backgroundColor: "red",
                      display: selected ? "block" : "none",
                    }}
                  />
                )}
                <button.Icon sx={{ color: "offblack.1" }} />
                <ListItemText sx={{ paddingBottom: 0, marginBottom: 0 }}>
                  <TranslatedTypography
                    doTranslation={button.translate ?? true}
                  >
                    {button.name}
                  </TranslatedTypography>
                </ListItemText>
                {!column && (
                  <Box
                    sx={{
                      width: "20px",
                      height: "2px",
                      backgroundColor: "red",
                      display: selected ? "block" : "none",
                    }}
                  />
                )}
              </Stack>
            </ListItemButton>
          );
        })}
        <LanguageSelector></LanguageSelector>
      </List>
    </Box>
  );
};

function NavigationMenu({ children, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const mdBreakpoint = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (mdBreakpoint) return <NavMenu></NavMenu>;

  return (
    <>
      <IconButton onClick={handleClick}>
        <MenuIcon color="offblack"></MenuIcon>
      </IconButton>
      <Menu
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
      >
        <NavMenu column={true}></NavMenu>
      </Menu>
    </>
  );
}

export default NavigationMenu;
